import { useModule } from '@/controllers/manager'

export default {
    namespaced: true,
    state: {
        module: 'users',
        loading: false,
    },
    getters: {},
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload
        },
    },
    actions: {
        async fetchUsers({ state, commit }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                return await controller.getAll(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async fetchOneUser({ state, commit }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                return await controller.getById(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async setUser({ commit, state }, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                if (payload.id)
                    await controller.update(payload)
                else
                    await controller.create(payload)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async deleteUser({ commit, state }, id) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                await controller.deleteById(id)
            } catch (e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async setPassword({ commit, state}, object) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                const payload = { password: object.password, r_password: object.r_password }
                await controller.setPassword(payload, object.token)
            } catch(e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async sendVerificationEmail({ commit, state}, payload) {
            commit('SET_LOADING', true)
            const controller = useModule(state.module)
            try {
                await controller.sendVerificationEmail(payload)
            } catch(e) {
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        }
    },
}
