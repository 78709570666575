import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n/index'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUsersListTable = ref(null)

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'name', label: i18n.t('usersTable.name'), sortable: true },
      { key: 'lastName', label: i18n.t('usersTable.last'), sortable: true },
      { key: 'email', label: i18n.t('usersTable.email'), sortable: true },
      { key: 'role', label: i18n.t('usersTable.role'), sortable: true },
      { key: 'dni', label: i18n.t('usersTable.dni'), sortable: true },
      { key: 'isActive', label: i18n.t('usersTable.isActive'), sortable: true },
      { key: 'phone', label: i18n.t('usersTable.phone'), sortable: true },
      { key: 'prefferedLanguage', label: i18n.t('usersTable.prefferedLanguage'), sortable: true },
      { key: 'verified', label: i18n.t('usersTable.verified'), sortable: false },
      { key: 'actions', label: '' },
    ]
  })
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isActive = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refUsersListTable.value
      ? refUsersListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refUsersListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const onSubmit = (data) => {
    store
      .dispatch('app-users/setUser', data)
      .then(() => {
        router.push({ name: 'users-list' })
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.success'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-users/fetchUsers', {
        headers: {
          'X-Sort': `users.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
          'X-Paginate': true,
        },
        query: {
          search: searchQuery.value,
          page: currentPage.value,
          perPage: perPage.value,
        },
      })
      .then((res) => {
        const { total } = res
        totalItems.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.fetch.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listUsersByRole = (role = '', parsed = false) =>
    new Promise((resolve, reject) => {
      store
        .dispatch('app-users/fetchUsers', {
          headers: {
            'X-Sort': `users.${sortBy.value}`,
            'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
            'X-Paginate': false,
          },
          query: {
            search: '',
            perPage: perPage.value,
            page: currentPage.value,
            role,
            isActive: isActive.value
          },
        })
        .then((res) => {
          const { total } = res
          totalItems.value = total

          if (!parsed) {
            resolve(res.data)
            return
          }

          const parsedUsers = []
          res.data.forEach((element) => {
            parsedUsers.push({
              key: element.id,
              label: `${element.name} ${element.lastName}`,
            })
          })
          resolve(parsedUsers)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.fetch.error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          reject(e)
        })
    })

  const deleteUser = (id) => {
    store
      .dispatch('app-users/deleteUser', id)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.success'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const sendMail = (email) => {
    const payload = { email: email }
    store
      .dispatch('app-users/sendVerificationEmail', payload)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.sendEmail.success'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.sendEmail.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveStatusVariant = (status) => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    return 'primary'
  }

  const resolveStatusText = (status) => {
    if (status === 0) return i18n.t('usersStatus.visibility.inactive')
    if (status === 1) return i18n.t('usersStatus.visibility.active')
    return '-'
  }

  const resolveVerifiedVariant = (verifiedAt) => {
    if (verifiedAt) return 'success'
    if (!verifiedAt) return 'warning'
    return '-'
  }

  const resolveVerifiedText = (verifiedAt) => {
    if (verifiedAt) return i18n.t('usersStatus.active.yes')
    if (!verifiedAt) return i18n.t('usersStatus.active.no')
    return '-'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUsersListTable,

    listUsersByRole,
    refetchData,
    onSubmit,
    deleteUser,
    sendMail,

    // ui
    resolveStatusVariant,
    resolveStatusText,
    resolveVerifiedVariant,
    resolveVerifiedText,
  }
}
